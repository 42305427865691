'use client';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { AnimatePresence, motion } from 'framer-motion';
import { useLenis } from 'lenis/react';
import { ChangeEventHandler, ChangeEvent, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useMedia } from 'react-use';
import useMeasure from 'react-use-measure';
import SimpleBar from 'simplebar-react';
import ArrowDownIcon from '~/icons/arrow-down.svg';
import Checkbox from '../Checkbox/Checkbox';
import Selectable from '../Selectable/Selectable';
import {
  cvaArrowIcon,
  cvaButton,
  cvaCheckbox,
  cvaCount,
  cvaHeader,
  cvaList,
  cvaListWrapper,
  cvaOverlay,
  cvaRoot,
  cvaScroll,
  cvaSearch,
  cvaSearchIcon,
  cvaSearchInput,
} from './DropdownStyles';
import IconClose from '/public/icons/close2.svg';
import IconSearch from '/public/icons/search4.svg';

interface Value {
  id?: string | number;
  value: string | number;
  label: string;
}

interface Props<T extends boolean> {
  className?: string;
  listItemClassName?: string;
  selectInnerClassName?: string;
  multiselect?: boolean;
  name: string;
  placeholder?: string;
  title?: string;
  buttonClassName?: string;
  arrowClassName?: string;
  value?: (T extends true ? Value['value'] : Value['value'][]) | null;
  data: Value[];
  place?: 'left' | 'right';
  onChange?: ChangeEventHandler<HTMLInputElement>;
  // selectedSlot?: (
  //   value:
  //     | (T extends true
  //         ? Value['value']
  //         : Value['value'][])
  //     | null
  // ) => ReactNode;
  searchTitle?: string;
  count?: number;
  onOpen?: (value: boolean) => void;
  getIsChecked?: number[];
  onLoad?: () => void;
  onCloseNaznachenie?: () => void;
}

export const Dropdown = <T extends boolean>({
  className,
  multiselect = false,
  name,
  value = null,
  data = [],
  place = 'left',
  searchTitle,
  buttonClassName,
  arrowClassName,
  count,
  onOpen,
  placeholder,
  onChange,
  getIsChecked,
  selectInnerClassName,
  onLoad,
  title,
  onCloseNaznachenie,
}: Props<T>) => {
  const [search, setSearch] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const [buttonRef, buttonMeasure] = useMeasure({
    scroll: true,
  });

  const isMobile = useMedia('(max-width: 767px)', true);

  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => {
    if (data?.length) {
      onOpen?.(false);
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (!multiselect) {
      setIsOpen(false);
      onOpen?.(false);
    }
  }, [multiselect, value]);

  useEffect(() => {
    if (onLoad && isOpen) {
      onLoad();
    }

    if (isOpen && onCloseNaznachenie) {
      onCloseNaznachenie();
    }
  }, [isOpen]);

  const lenis = useLenis();

  useEffect(() => {
    setIsOpen(false);
    onOpen?.(false);
  }, [lenis?.scroll]);

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  }, []);

  const dataFilterBySearch = useMemo(() => {
    const substr = search.trim().toLowerCase();

    return data.filter(({ label }) => {
      const str = label.trim().toLowerCase();
      return str.includes(substr);
    });
  }, [data, search]);

  useEffect(() => {
    if (isOpen) {
      setSearch('');
    }
  }, [isOpen]);

  return (
    <div ref={ref} className={twMergeExt(cvaRoot(), className)}>
      <button
        ref={buttonRef}
        className={twMergeExt(cvaButton(), buttonClassName)}
        type={'button'}
        onClick={() =>
          setIsOpen((prev) => {
            onOpen?.(!prev);
            return !prev;
          })
        }
      >
        <div>{data?.find((i) => `${i.value}` === `${value}`)?.label ?? placeholder}</div>
        {count ? <div className={cvaCount()}>{count}</div> : null}
        <ArrowDownIcon className={twMergeExt(cvaArrowIcon({ isOpen }), arrowClassName)} />
      </button>

      <AnimatePresence>
        {isOpen && data.length > 0 ? (
          <>
            <div className={cvaOverlay()} onClick={() => setIsOpen(false)}></div>
            <motion.div
              className={twMergeExt(
                cvaListWrapper({
                  place,
                  multiselect,
                })
              )}
              initial={{
                y: '0.2rem',
                opacity: 0,
              }}
              animate={{
                y: '1.2rem',
                opacity: 1,
              }}
              exit={{ y: '0.2rem', opacity: 0 }}
              transition={{
                duration: 0.3,
                ease: 'easeOut',
              }}
              style={{
                top: isMobile ? 'auto' : buttonMeasure.height,
                bottom: isMobile ? 0 : 'auto',
              }}
            >
              {isMobile ? (
                <div className="py-1.4 px-2.4 bg-cLightGrey rounded-tl-[2rem] rounded-tr-[2rem] flex items-center justify-between">
                  <div></div>
                  <span className="text-labelL">{title}</span>
                  <IconClose onClick={() => setIsOpen(false)} className="w-2.4 h-2.4 fill-cBlack" />
                </div>
              ) : null}
              {searchTitle?.length ? (
                <div className={cvaHeader()}>
                  <div className={cvaSearch()}>
                    <input
                      type="text"
                      className={cvaSearchInput()}
                      value={search}
                      placeholder={searchTitle}
                      onChange={onSearch}
                    />
                    <IconSearch className={cvaSearchIcon()} />
                  </div>
                </div>
              ) : null}
              <SimpleBar data-lenis-prevent className={cvaScroll()}>
                <div className={cvaList()}>
                  {dataFilterBySearch.map((i) =>
                    multiselect ? (
                      <Checkbox
                        type={multiselect ? 'checkbox' : 'radio'}
                        className={cvaCheckbox()}
                        key={i.id}
                        name={name}
                        value={i.value}
                        innerClassName={selectInnerClassName}
                        title={i.label}
                        checked={getIsChecked?.includes(i.value as number)}
                        inDropdown
                        onChange={onChange}
                      />
                    ) : (
                      <Selectable
                        type={multiselect ? 'checkbox' : 'radio'}
                        className={cvaCheckbox()}
                        innerClassName={selectInnerClassName}
                        key={i.id}
                        name={name}
                        value={i.value}
                        title={i.label}
                        inDropdown
                        onChange={onChange}
                      />
                    )
                  )}
                </div>
              </SimpleBar>
            </motion.div>
          </>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

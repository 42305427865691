import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['DropdownStyles-cvaRoot', 'relative', 'text-bodyM text-cBlack', 'group/dropdown']);

export const cvaButton = cva([
  'DropdownStyles-cvaButton',
  'relative',
  'inline-flex gap-0.8',
  'px-1.6 pt-1.1 pb-1.3',
  'border border-cBlack32 rounded-[1.6rem]',
  'transition-all duration-300',
  'cursor-pointer',
  'group-hover/dropdown:border-cBlack',
]);

export const cvaCount = cva([
  'DropdownStyles-cvaCount',
  'flex items-center justify-center',
  'w-1.6 h-1.6',
  '-ml-1.2',
  'text-bodyXXS text-cWhite',
  'bg-cMediumGreen',
  'rounded-full border-0.1 border-cWhite',
]);

export const cvaArrowIcon = cva(
  ['DropdownStyles-cvaArrowIcon', 'w-1.6 h-1.6', 'mt-0.5', 'transition-transform duration-300 ease-out'],
  {
    variants: {
      isOpen: {
        true: ' sm:rotate-180 rotate-0',
        false: ' sm:rotate-0 -rotate-90',
      },
    },
  }
);

export const cvaListWrapper = cva(
  [
    'DropdownStyles-cvaListWrapper',
    'sm:absolute fixed sm:top-0 bottom-0 z-10',
    'sm:pb-2.4 pb-[10rem]',
    'w-full min-w-[32.4rem] h-fit ',
    'rounded-[1.6rem]',
    'shadow-map bg-cWhite',
  ],
  {
    variants: {
      place: {
        right: 'right-0',
        left: 'left-0',
      },
      multiselect: {
        true: [''],
        false: [''],
      },
    },
    defaultVariants: {
      place: 'left',
      multiselect: false,
    },
  }
);

export const cvaHeader = cva(['DropdownStyles-cvaHeader', 'p-2.4']);

export const cvaScroll = cva(['DropdownStyles-cvaScroll', 'w-full max-h-[28rem]']);

export const cvaInput = cva([
  'DropdownStyles-cvaInput',
  'absolute top-0 left-0',
  'appearance-none',
  '[&:checked+.DropdownStyles-cvaMark]:visible [&:checked+.DropdownStyles-cvaMark]:opacity-100',
]);

export const cvaList = cva(['DropdownStyles-cvaList', 'flex flex-col', 'w-full']);

export const cvaCheckbox = cva(['DropdownStyles-cvaCheckbox', '!m-0']);

export const cvaListItem = cva([
  'DropdownStyles-cvaListItem',
  'inline-flex justify-between gap-1.6',
  'px-2.4 py-1.6',
  'cursor-pointer select-none',
]);

export const cvaMark = cva(
  [
    'DropdownStyles-cvaMark',
    'self-start',
    'grow-0 shrink-0 ',
    ' text-cMediumGreen',
    'transition-all duration-300 ease-out',
  ],
  {
    variants: {
      multiselect: {
        false: 'w-1.6 h-1.6 mt-0.4 invisible opacity-0',
        true: '',
      },
    },
  }
);

export const cvaSearch = cva(['DropdownStyles-cvaSearch', 'relative']);

export const cvaSearchInput = cva([
  'DropdownStyles-cvaSearchInput',
  'block',
  'w-full h-4.8',
  'py-1.6 px-2.4',
  'bg-cLightGrey',
  'rounded-[1.6rem]',
  'border-none outline-none',
  'text-bodyM placeholder:text-cBlack48',
  'focus:placeholder:opacity-0',
  'transition-all duration-300',
  'hover:bg-cMediumGreyOnWhite',
]);

export const cvaSearchIcon = cva([
  'DropdownStyles-cvaSearchIcon',
  'absolute z-[1] right-2.4 top-1/2 -translate-y-1/2',
  'w-2.4 h-2.4',
  'pointer-events-none',
]);

export const cvaOverlay = cva([
  'DropdownStyles-cvaOverlay',
  'sm:hidden flex ',
  'fixed w-screen h-screen bottom-0 left-0 bg-black/30 z-10',
]);

import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'CheckboxStyles-cvaRoot',
  'relative',
  'block',
  'not-last:mb-1.6',
  'cursor-pointer select-none',
  'group',
]);

export const cvaInput = cva(['CheckboxStyles-cvaInput', 'hidden', 'peer']);

export const cvaInner = cva(
  [
    'CheckboxStyles-cvaInner',
    'relative',
    'flex items-center',
    'min-h-[2.4rem]',
    'pl-4',
    'before:absolute before:top-1/2 before:-translate-y-1/2 before:w-2.4 before:h-2.4 before:rounded-[0.4rem] before:border-[0.2rem] before:border-cBlack32 before:transition-colors before:duration-300',
    'peer-checked:before:border-cMediumGreen',
    'transition-colors duration-300',
    'hover:before:border-cMediumGreen hover:before:bg-cLightGreenBtn',
  ],
  {
    variants: {
      inDropdown: {
        true: ['py-1.6 px-2.4 pl-6.4', 'before:left-2.4', 'group-hover:bg-cMediumGreyOnWhite'],
        false: ['before:left-0'],
      },
      type: {
        checkbox: ['peer-checked:before:bg-cMediumGreen'],
        radio: ['before:rounded-full peer-checked:before:border-[0.7rem]'],
      },
    },
    defaultVariants: {
      inDropdown: false,
      type: 'checkbox',
    },
  }
);

export const cvaCheckIcon = cva(
  [
    'CheckboxStyles-cvaCheckIcon',
    'absolute top-1/2 -translate-y-1/2 mt-0.1 z-[1]',
    'w-1.3 h-1.1',
    'fill-cWhite',
    'opacity-0',
    'transition-opacity duration-300',
    'peer-checked:opacity-100',
  ],
  {
    variants: {
      inDropdown: {
        true: ['left-2.9'],
        false: ['left-0.5'],
      },
    },
    defaultVariants: {
      inDropdown: false,
    },
  }
);

export const cvaTitle = cva(['CheckboxStyles-cvaTitle', 'inline-block', 'text-bodyM']);
